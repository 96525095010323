import logo from './logo.svg';
import './App.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import HomePage from './components/HomePage'; // Adjust the path as necessary
import AboutPage from './components/AboutPage';
import OrderConfirmationPage from './components/OrderConfirmationPage'
import Checkout from './components/Checkout';
import { Amplify } from 'aws-amplify';
import config from './amplifyconfiguration.json';

Amplify.configure(config);


export default function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route exact path="/checkout" element={<Checkout />} />
          <Route exact path="/order/:orderId" element={<OrderConfirmationPage />} />
          <Route path="*" element={<Navigate to="/" />} /> {/* Redirect all other paths to root */}
        </Routes>
      </Router>
    </>
  )
}