import React, { createContext, useState, useContext, useEffect, ReactNode } from 'react';
import Cookies from 'js-cookie';
import { compressToEncodedURIComponent, decompressFromEncodedURIComponent } from 'lz-string';
import { SelectedOptions, CartItem } from "./types/DishTypes";

interface CartContextType {
    isCartVisible: boolean;
    toggleCartVisibility: () => void;
    closeCart: () => void;
    isCheckoutOpen: boolean;
    openCheckout: () => void;
    cartItems: any[];
    setCartItems: React.Dispatch<React.SetStateAction<any[]>>;
    isEditingItem: boolean;
    setIsEditingItem: React.Dispatch<React.SetStateAction<boolean>>;
    itemInEdit: any;
    setItemInEdit: React.Dispatch<React.SetStateAction<any>>;
    updateCart: (item: any) => void;
    subtotal: number;
    tax: number;
    total: number;
    handleAddToCart: (newItem: any) => void;
    totalItems: number,
}

// Set up a default value for the context
const defaultCartContext: CartContextType = {
    isCartVisible: false,
    toggleCartVisibility: () => { },
    closeCart: () => { },
    isCheckoutOpen: false,
    openCheckout: () => { },
    cartItems: [],
    setCartItems: () => { },
    isEditingItem: false,
    setIsEditingItem: () => { },
    itemInEdit: null,
    setItemInEdit: () => { },
    updateCart: () => { },
    subtotal: 0,
    tax: 0,
    total: 0,
    handleAddToCart: () => { },
    totalItems: 0
};

// Create the context with a default value
const CartContext = createContext<CartContextType>(defaultCartContext);

// Create a custom hook to use the cart context
export const useCart = () => useContext(CartContext);

// Helper function to save cart items in cookies with compression
const saveCartToCookies = (cartItems: CartItem[]) => {
    const compressedCart = compressToEncodedURIComponent(JSON.stringify(cartItems));
    Cookies.set('cart', compressedCart, { expires: 7 }); // Cookie lasts for 7 days
};

// Helper function to load cart items from cookies with decompression
export const loadCartFromCookies = () => {
    const compressedCart = Cookies.get('cart');
    if (!compressedCart) return [];
    const decompressedCart = decompressFromEncodedURIComponent(compressedCart);
    return decompressedCart ? JSON.parse(decompressedCart) : [];
};

interface CartProviderProps {
    children: ReactNode;
}

// Create a provider component
export const CartProvider: React.FC<CartProviderProps> = ({ children }) => {
    const [isCartVisible, setIsCartVisible] = useState(false);
    const [isCheckoutOpen, setIsCheckoutOpen] = useState(false);
    const [isEditingItem, setIsEditingItem] = useState(false);
    const [cartItems, setCartItems] = useState(loadCartFromCookies); // Load from cookies
    const [subtotal, setSubtotal] = useState(0);
    const [tax, setTax] = useState(0);
    const [taxRate, setTaxRate] = useState(0.1);
    const [total, setTotal] = useState(0);
    const [itemInEdit, setItemInEdit] = useState(null);
    const [totalItems, setTotalItems] = useState(0);

    const closeCart = () => {
        setIsCartVisible(false);
    };

    const handleAddToCart = (newItem: CartItem) => {
        setCartItems((currentCart: CartItem[]) => {
            // Check if the item already exists in the cart
            const existingItemIndex = currentCart.findIndex((item: CartItem) =>
                item.id === newItem.id &&
                JSON.stringify(item.selectedOptions) === JSON.stringify(newItem.selectedOptions)
            );

            let updatedCart;
            if (existingItemIndex > -1) {
                // If item exists, create a new cart array with updated quantity
                updatedCart = currentCart.map((item: CartItem, index: number) => {
                    if (index === existingItemIndex) {
                        // Increment the quantity of the existing item
                        return { ...item, quantity: item.quantity + newItem.quantity };
                    }
                    return item;
                });
            } else {
                // If item does not exist, add the new item to the cart
                newItem["itemIndex"] = currentCart.length;
                updatedCart = [...currentCart, newItem];
            }

            saveCartToCookies(updatedCart); // Save updated cart to cookies
            return updatedCart;
        });
    };

    const toggleCartVisibility = () => {
        setIsCartVisible(!isCartVisible);
    };

    const openCheckout = () => {
        setIsCheckoutOpen(true);
    };

    // Define the updateCart function
    const updateCart = (item: CartItem) => {
        const index = item.itemIndex;
        setCartItems((currentItems: CartItem[]) => {
            const newItems = [...currentItems];
            newItems[index] = item;
            saveCartToCookies(newItems); // Save updated cart to cookies
            return newItems;
        });
    };

    // Update subtotal, tax, and total whenever cartItems changes
    useEffect(() => {
        const newSubtotal = cartItems.reduce((total: number, item: CartItem) => total + item.calculatedPrice * item.quantity, 0);
        setSubtotal(newSubtotal);
        setTax(newSubtotal * taxRate);
        setTotal(newSubtotal * (1 + taxRate));
        setTotalItems(cartItems.reduce((total: number, item: { quantity: number }) => total + item.quantity, 0));
        saveCartToCookies(cartItems)
    }, [cartItems]);

    return (
        <CartContext.Provider value={{ isCartVisible, toggleCartVisibility, closeCart, isCheckoutOpen, openCheckout, cartItems, setCartItems, isEditingItem, setIsEditingItem, itemInEdit, setItemInEdit, updateCart, subtotal, tax, total, handleAddToCart, totalItems }}>
            {children}
        </CartContext.Provider>
    );
};
