import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js";

const Payment = forwardRef((props, ref) => {
  const stripe = useStripe();
  const elements = useElements();
  const paymentFormRef = useRef();

  useImperativeHandle(ref, () => ({
    submitForm: async () => {
      if (!stripe || !elements) {
        throw new Error("Stripe.js has not loaded yet.");
      }

      const result = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: "http://localhost:3000/", // Set your return URL
        },
      });

      if (result.error) {
        console.error("Error in payment:", result.error.message);
        throw result.error; // Throw error to parent
      }

      return result; // Return result to parent
    },
  }));

  return (
    <form ref={paymentFormRef}>
      <PaymentElement />
    </form>
  );
});

export default Payment;
