
import { generateClient } from '@aws-amplify/api';
import { GraphQLAPI, graphqlOperation, GraphQLResult } from '@aws-amplify/api-graphql';
import { getRestaurant, menuSectionsByRestaurantID, getMenuItem } from '../graphql/queries';
import { Restaurant, MenuSection, MenuItem } from '../models';
import { create } from 'zustand'

// Initialize the AWS Amplify client
const client = generateClient({ authMode: 'apiKey' });

// Zustand store
interface RestaurantState {
    restaurant: Restaurant | null;
    menuSections: MenuSection[];
    menuItems: Record<string, MenuItem>;
    menuItemsOptions: Record<string, MenuItem>;
    loading: boolean;
    error: string | null;
    fetchRestaurant: (id: string) => Promise<void>;
    fetchMenuSections: (restaurantID: string) => Promise<void>;
    fetchMenuItem: (menuItemId: string) => Promise<void>;
}

export const useRestaurantStore = create<RestaurantState>((set: any, get: any) => ({
    restaurant: null,
    menuSections: [],
    menuItems: {},
    menuItemsOptions: {},
    loading: false,
    error: null,

    fetchRestaurant: async (id: string) => {
        const { restaurant } = get(); // Access the current state
        if (restaurant) {
            // If restaurant data is already present, don't fetch again

            return;
        }
        set({ loading: true, error: null });
        try {
            const result: GraphQLResult<any> = await client.graphql({
                query: getRestaurant,
                variables: { id },
            });

            set({ restaurant: result.data.getRestaurant, loading: false });
        } catch (err) {
            console.error(err)
            if (err instanceof Error) {
                console.error('Error fetching restaurants:', err);
                set({ error: err.message, loading: false });
            }
        }
    },

    fetchMenuSections: async (restaurantID: string) => {
        const { menuSections } = get(); // Access the current state

        if (menuSections.length > 0) {
            // If restaurant data is already present, don't fetch again
            return;
        }
        set({ loading: true, error: null });
        try {
            const result: GraphQLResult<any> = await client.graphql({
                query: menuSectionsByRestaurantID,
                variables: { restaurantID: restaurantID },
            });

            set({ menuSections: result.data.menuSectionsByRestaurantID.items, loading: false });
        } catch (err) {
            if (err instanceof Error) {
                set({ error: err.message, loading: false });
            }
            console.error('Error fetching menu sections:', err);
        }
    },

    fetchMenuItem: async (menuItemId: string) => {
        const { menuItems } = get(); // Access the current state

        if (menuItems[menuItemId]) {
            // If restaurant data is already present, don't fetch again
            return;
        }
        set({ loading: true, error: null });
        try {
            const result: GraphQLResult<any> = await client.graphql({
                query: getMenuItem,
                variables: { id: menuItemId },
            });

            set((state: any) => ({
                menuItems: {
                    ...state.menuItems,
                    [menuItemId]: result.data.getMenuItem, // Store menu item by ID
                },
                menuItemsOptions: ({
                    ...state.menuItemsOptions,
                    [menuItemId]: result.data.getMenuItem.menuItemOptions.items, // Store menu item by ID
                }),
                loading: false,
            }));
        } catch (err) {
            if (err instanceof Error) {
                console.error('Error fetching menu item:', err);
                set({ error: err.message, loading: false });
            }
        }
    },
}));
