import { post } from 'aws-amplify/api';
import { loadCartFromCookies } from '../CartContext';
import Cookies from 'js-cookie';

export async function placeOrder(tipAmount: string) {
    const cartItems = loadCartFromCookies();
    const authToken = Cookies.get('authToken') || "";

    try {
        const restOperation = post({
            apiName: 'quicksnackz',
            path: '/placeorder',
            options: {
                body: {
                    "cartItems": cartItems,
                    "tipAmount": tipAmount,
                    "authToken": authToken,
                },
                headers: {
                    'Content-Type': 'application/json', // Ensure proper content type
                },
            }
        });

        const { body } = await restOperation.response;
        const response = await body.json();

        console.log('POST call succeeded');
        console.log(response);

        return response;
    } catch (err) {
        console.error(err)
        if (err instanceof Error) {
            console.error('Error fetching restaurants:', err);
        }
    }
}

export async function createPaymentIntent(totalAmount: string) {
    const authToken = Cookies.get('authToken') || "";

    try {
        const restOperation = post({
            apiName: 'quicksnackz',
            path: '/createPaymentIntent',
            options: {
                body: {
                    "totalAmount": totalAmount,
                    "currency": "usd",
                    "authToken": authToken
                },
                headers: {
                    'Content-Type': 'application/json', // Ensure proper content type
                },
            }
        });

        const { body } = await restOperation.response;
        const response = await body.json();

        console.log('POST call succeeded');
        console.log(response);

        return response;
    } catch (err) {
        console.error(err)
        if (err instanceof Error) {
            console.error('Error while creating payment intent:', err);
        }
    }
}