import React from 'react';
import { useUser } from '../UserContext';

const OrderConfirmation = ({ }) => {
    const { email } = useUser();
    return (
        <div className="min-h-screen flex flex-col items-center bg-gray-100 py-10 px-6">
            {/* Header */}
            <div className="text-center mb-6">
                <h1 className="text-3xl font-bold text-green-600">Thank You for Your Order!</h1>
                <p className="text-gray-700 mt-2">
                    Your order has been placed successfully.
                </p>
            </div>


            {/* Email Confirmation */}
            <div className="mt-6 text-center">
                <p className="text-sm text-gray-600">
                    A receipt has been sent to your email at{' '}
                    <span className="font-medium text-gray-800">{email}</span>.
                </p>
            </div>

            {/* Actions */}
            <div className="mt-6 flex gap-4">
                <button
                    onClick={() => window.location.href = '/'}
                    className="px-6 py-2 text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 rounded-lg"
                >
                    Home
                </button>
            </div>
        </div>
    );
};

export default OrderConfirmation;
