import React, { useState, useRef } from "react";
import { useCart } from "../CartContext";
import { Navigate } from "react-router-dom";
import { useUser } from "../UserContext";
import RestaurantInfo from "./RestaurantInfo";
import GoogleMapEmbed from "./GoogleMapEmbed";
import UserInfo from "./UserInfo";
import { OrderDetails } from "./OrderDetails";
import { CheckoutSummary } from "./CheckoutSummary";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { placeOrder } from "../api/restApis";
import Payment from "./Payment";

const stripePromise = loadStripe("pk_test_51Oplw4IogR4j3vVVdSTe6ZuMu6nFBe1ZjJAr4N7V5QdBSoLpq5KpFZ9YLFXNvO0BYGewqdtuXDzJRnCPpmOggHkC00VJq6pRSA");

const Checkout = () => {
  const { isLoggedIn } = useUser();
  const { cartItems } = useCart();

  const [tipAmount, setTipAmount] = useState(0.0);
  const [clientSecret, setClientSecret] = useState(null);

  const paymentFormRef = useRef(null);

  const shouldNavigate = () => cartItems.length === 0 || !isLoggedIn;

  const handleContinueToPaymentClick = async (e) => {
    e.preventDefault();
    try {
      const response = await placeOrder(tipAmount);
      setClientSecret(response.clientSecret); // Set the client secret for Stripe Elements
    } catch (error) {
      console.error("An error occurred while placing the order:", error);
      alert("An unexpected error occurred. Please try again.");
    }
  };

  const handleGoBackToCheckoutClick = () => setClientSecret(null);

  const handleCompletePaymentClick = async (e) => {
    e.preventDefault();
    if (paymentFormRef.current) {
      try {
        const result = await paymentFormRef.current.submitForm(); // Call submitForm exposed by Payment component
        console.log("Payment result:", result);
      } catch (error) {
        console.error("An error occurred while confirming payment:", error);
        alert("An unexpected error occurred. Please try again.");
      }
    }
  };

  return !shouldNavigate() ? (
    <div className="max-w-2xl mx-auto bg-white shadow-md rounded-lg overflow-hidden">
      <div className="px-12 py-4">
        {clientSecret ? (
          <div>
            <Elements stripe={stripePromise} options={{ clientSecret }}>
              <Payment ref={paymentFormRef} />
            </Elements>
            <div className="flex w-full mt-6">
              <button
                className="flex-1 border border-red-500 bg-red-500 text-white py-3 rounded-full mr-2"
                onClick={handleGoBackToCheckoutClick}
              >
                Go back to Checkout
              </button>
              <button
                className="flex-1 border border-blue-500 bg-blue-500 text-white py-3 rounded-full ml-2"
                onClick={handleCompletePaymentClick}
              >
                Complete Payment
              </button>
            </div>
          </div>
        ) : (
          <div>
            <RestaurantInfo />
            <GoogleMapEmbed />
            <UserInfo />
            <OrderDetails />
            <CheckoutSummary tipAmount={tipAmount} setTipAmount={setTipAmount} />
            <button
              className="w-full bg-blue-500 text-white py-3 rounded-full mt-6"
              onClick={handleContinueToPaymentClick}
            >
              Continue to Payment
            </button>
          </div>
        )}
        <p className="text-xs text-gray-600 mt-4">
          By completing your order, you give QuickSnackz and this restaurant or restaurant group permission to send you
          digital receipts, order updates powered by QuickSnackz, and marketing materials. Info for CA residents:
          available here. Message frequency varies and message and data rates may apply. Reply STOP to opt out. Program
          is subject to the QuickSnackz Guest Terms of Service and QuickSnackz's Privacy Statement.
        </p>
      </div>
    </div>
  ) : (
    <Navigate to="/" />
  );
};

export default Checkout;
